import { Dashboard } from "./charts.dashboard";
import { Insights } from "./charts.insights";
import { Omni } from "./charts.omni";
import { BulkMail } from "./charts.bulk-mail";
import { ShortCodes } from "./charts.shortcodes";
import { ShortUrl } from "./charts.shorturl";

// import {Chart, registerables} from 'chart.js';
// Chart.register(...registerables);
// Alternative Single Line Import
//import Chart from 'chart.js/auto';

class Charts {
    Dashboard = new Dashboard();
    Insights = new Insights();
    Omni = new Omni();
    BulkMail = new BulkMail();
    ShortCodes = new ShortCodes();
    ShortUrl = new ShortUrl();
}
(<any>window).Charts = new Charts();