import Chart from 'chart.js/auto';
import { Helpers } from "./chart.helpers";
import { LegendUtil } from "./charts.custom";

export class Dashboard {

    public pies: Map<string, any> = new Map<string, any>();
    public bars: Map<string, any> = new Map<string, any>();

    public boardBars: Map<string, any> = new Map<string, any>();

    public Pie(canvasId: string, json: string): void {
        try {
            let jsonObj = JSON.parse(json);
            let labels: string[] = [];
            let data: string[] = [];
            let colors: string[] = [];
            for (let i = 0; i < jsonObj.length; i++) {
                let o = jsonObj[i];
                labels.push(o.Name);
                data.push(o.Count);
                colors.push(Helpers.GetRandomRgb());
            }

            let pie = this.pies.get(canvasId);

            if (pie) {
                pie.destroy();
            }
            pie = this.renderChart(canvasId, {
                type: 'pie',
                data: {
                    labels: labels,
                    datasets: [{
                        data: data,
                        backgroundColor: colors,
                        hoverOffset: 4
                    }]
                },
                options: {
                    responsive: false,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                            position: 'bottom'
                        }
                    }
                }
            });
            this.pies.set(canvasId, pie);
        } catch (e) {
            console.warn(e);
        }
    }

    public Bar(canvasId: string, json: string): void {
        try {
            let jsonObj = JSON.parse(json);

            let labels: number[] = [];
            for (let i = 1; i < 32; i++) {
                labels.push(i);
            }

            let datasets: any[] = []
            for (let i = 0; i < jsonObj.length; i++) {
                let set = jsonObj[i];
                let colors: string[] = [];
                for (let i = 1; i < 32; i++) {
                    colors.push(Helpers.GetRandomRgb());
                }
                datasets.push({
                    label: set.label,
                    data: set.data,
                    backgroundColor: colors,
                    borderColor: colors,
                    borderWidth: 1
                });
            }

            const config = {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: datasets,
                },
                options: {
                    responsive: false,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                            position: 'bottom'
                        },
                        scales: {
                            y: {
                                beginAtZero: true
                            }
                        }
                    }
                }
            };
            let bar = this.bars.get(canvasId);
            if (bar) {
                bar.destroy();
            }
            bar = this.renderChart(canvasId, config);
            this.bars.set(canvasId, bar);
        } catch (e) {
            console.warn(e);
        }
    }
    public CountryDeliveryBar(canvasId: string, json: string): void {
        try {
            let jsonObj = JSON.parse(json);

            let labels: string[] = [];
            let countries = jsonObj[0].countries;
            for (let i = 0; i < countries.length; i++) {
                labels.push(countries[i]);
            }

            let datasets: any[] = []
            for (let i = 0; i < jsonObj.length; i++) {
                let set = jsonObj[i];
                let colors: string[] = [];
                for (let i = 0; i < countries.length; i++) {
                    colors.push(Helpers.GetRandomRgb());
                }
                datasets.push({
                    label: set.label,
                    data: set.data,
                    backgroundColor: colors,
                    borderColor: colors,
                    borderWidth: 1
                });
            }

            const config = {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: datasets,
                },
                options: {
                    responsive: false,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                            position: 'bottom',
                        },
                        scales: {
                            y: {
                                beginAtZero: true
                            },
                            x: {
                                ticks: {
                                    autoSkip: false,
                                    maxRotation: 90
                                }
                            }
                        }
                    }
                }
            };
            let bar = this.bars.get(canvasId);
            if (bar) {
                bar.destroy();
            }
            bar = this.renderChart(canvasId, config);
            this.bars.set(canvasId, bar);
        } catch (e) {
            console.warn(e);
        }
    }
    public BoardBar(canvasId: string, json: string , bankName: string = "SBSA"): void {
        try {
            let bar = this.GetBar(canvasId);
            if (bar) bar.destroy();

            let jsonObj = JSON.parse(json);

            let labels: string[] = [];
            let networkLabels: string[] = ["MTN", "Telkom", "Cell C", "Vodacom"];

            if (networkLabels.includes(jsonObj[0].name)) {
                labels.push(`${bankName} → OCEP`);
                labels.push("OCEP → MNOs");
                labels.push("Confirmed Delivery");
                labels.push("Pending MNO Receipts");
                labels.push("Failed");
            }
            else
            {
                labels.push(`${bankName} → OCEP (${(jsonObj[0].data[0]).toLocaleString('fr-FR')})`);
                labels.push(`OCEP → MNOs (${(jsonObj[0].data[1]).toLocaleString('fr-FR')})`);
                labels.push(`Confirmed Delivery (${jsonObj[0].data[2].toLocaleString('fr-FR')})`);
                labels.push(`Pending MNO Receipts (${jsonObj[0].data[3].toLocaleString('fr-FR')})`);
                labels.push(`Failed (${jsonObj[0].data[4].toLocaleString('fr-FR')})`);
            }

            // red/green/blue
            let colors: string[] = [];
            colors.push(`rgb(102, 204, 0)`);
            colors.push(`rgb(255, 102, 0)`);
            colors.push(`rgb(0, 102, 204)`);
            colors.push(`rgb(255, 255, 0)`);
            colors.push(`rgb(204, 0, 0)`);

            let datasets: any[] = []
            for (let i = 0; i < jsonObj.length; i++) {
                let set = jsonObj[i];
                datasets.push({
                    label: set.label,
                    data: set.data,
                    backgroundColor: colors,
                    borderColor: colors,
                    borderWidth: 1
                });
            }

            const config = {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: datasets,
                },
                options: {
                    responsive: false,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                            position: 'bottom'
                        },
                        scales: {
                            y: {
                                beginAtZero: true
                            }
                        }
                    }
                }
            };

            LegendUtil.generateAndApplyOptions(canvasId, config);
            this.SetBar(canvasId, this.renderChart(canvasId, config));
        } catch (e) {
            console.warn(e);
        }
    }

    public BoardLine(canvasId: string, colorSet: number, json: string): void {
        try {
            let bar = this.GetBar(canvasId);
            if (bar) bar.destroy();

            let jsonObj = JSON.parse(json);

            let labels: number[] = [];
            for (let i = 0; i < 24; i++) {
                labels.push(i);
            }

            let colors: string[] = [];
            switch (colorSet) {
                case 1: {
                    colors.push(`rgb(220, 20, 60)`);
                    colors.push(`rgb(255, 215, 0)`);
                    colors.push(`rgb(0, 128, 0)`);
                    colors.push(`rgb(0, 139, 139)`);
                    colors.push(`rgb(70, 130, 180)`);
                    colors.push(`rgb(0, 191, 255)`);
                    colors.push(`rgb(153, 50, 204)`);
                    colors.push(`rgb(210, 105, 30)`);
                }
                    break;

                case 2: {
                    colors.push(`rgb(102, 204, 0)`);
                    colors.push(`rgb(0, 102, 204)`);
                    colors.push(`rgb(255, 255, 0)`);
                    colors.push(`rgb(204, 0, 0)`);
                }
                    break;

                default: {
                    for (let i = 0; i < labels.length; i++) {
                        colors.push(Helpers.GetRandomRgb());
                    }
                }
                    break;
            }


            let datasets: any[] = [];
            for (let i = 0; i < jsonObj.length; i++) {
                let set = jsonObj[i];
                //console.log(set);
                datasets.push({
                    label: set.label,
                    data: set.data,
                    fill: false,
                    borderColor: colors[i], //Helpers.GetRandomRgb(),
                    backgroundColor: colors[i],
                    tension: 0.1,
                    spanGaps: true
                });
            }

            // let datasets: any[] = [{
            //     label: 'OTP',
            //     data: [65, 59, 80, 81, 56, 55, 40],
            //     fill: false,
            //     borderColor: Helpers.GetRandomRgb(),
            //     tension: 0.1
            // }, {
            //     label: 'DIC',
            //     data: [55, 49, 70, 71, 46, 45, 30],
            //     fill: false,
            //     borderColor: Helpers.GetRandomRgb(),
            //     tension: 0.1
            // }, {
            //     label: 'Beyond Payments',
            //     data: [65, 59, 80, 81, 56, 55, 40],
            //     fill: false,
            //     borderColor: Helpers.GetRandomRgb(),
            //     tension: 0.1
            // }, {
            //     label: 'MU Online',
            //     data: [65, 59, 80, 81, 56, 55, 40],
            //     fill: false,
            //     borderColor: Helpers.GetRandomRgb(),
            //     tension: 0.1
            // }, {
            //     label: 'MY Batch',
            //     data: [65, 59, 80, 81, 56, 55, 40],
            //     fill: false,
            //     borderColor: Helpers.GetRandomRgb(),
            //     tension: 0.1
            // }]

            const config = {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: datasets,
                },
                options: {
                    responsive: false,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                            position: 'bottom'
                        },
                        scales: {
                            y: {
                                beginAtZero: true,
                                stacked: true
                            }
                        }
                    }
                }
            };
            this.SetBar(canvasId, this.renderChart(canvasId, config));
        } catch (e) {
            console.warn(e);
        }
    }

    private GetBar(canvasId: string): any {
        return this.boardBars.get(canvasId);
    }

    private SetBar(canvasId: string, chart: any): void {
        this.boardBars.set(canvasId, chart);
    }

    protected renderChart(elementId: string, config: any): Chart|never {
        let el: HTMLCanvasElement = document.getElementById(elementId) as HTMLCanvasElement;
        let ctx = el.getContext('2d');
        return new Chart(ctx!, config);
    }
}